* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 100;
  font-style: normal;
}

/* upper banner start */

.text-center {
  position: relative;
}

.mySlides-small-cont {
  visibility: hidden;
}

img {
  vertical-align: middle;
}

/* Slideshow container */
.slideshow-container {
  /* max-width: 1000px;   used to keep the image width small upto 100px */
  position: relative;
  margin: auto;
  visibility: visible;
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

/* form css styling */
.colnew {
  position: absolute;
  top: 6vw;
  left: 25%;
  width: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}
.form-select {
  border: none;
}

.form-control {
  background-color: white;
  border-radius: 5;
  border: none;
  width: 16vw;
  box-shadow: 2px 2px 4px #000000;
}
.form-border {
  margin: 30px;
}
.tablet-slideshow-container {
  visibility: hidden;
}

.in-form-img {
  position: absolute;
  z-index: 1;
  margin-top: 10px;
  margin-left: 10%;
  width: 8%;
  left: 1%;
  top: 2vh;
}

/* form css styling end */

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .text {
    font-size: 11px;
  }
}

/* upper banner finish */

div.typewriter {
  display: block;
}

.back-main-img {
  width: 100%;
  height: auto;
}

.in-img {
  position: absolute;
  z-index: 1;
  margin-top: 10px;
  margin-left: 10%;
  width: 10%;
}

.in-text {
  /* position: absolute;
  z-index: 1;
  bottom: 0; */
  color: rgb(0, 0, 0);
  font-size: 3vw;
  font-weight: 500;
  text-align: left;
  margin-left: 10vw;
  /* margin-left: 10%;
  text-align: left; */
}

.col {
  position: absolute;
  top: 16vw;
  left: 0%;
}
.nav-item {
  margin-bottom: 0%;
}

.nav-item:hover {
  background-color: transparent;
}

.navbar-nav {
  margin-top: 0;
}

.submit-button-form {
  background-color: rgb(45, 185, 202);
  width: 10vw;
  padding: 10px;
  margin-bottom: 30px;
  border: none;
  box-shadow: 2px 2px 4px #000000;
}

.nav-resp {
  position: absolute;
  z-index: 1;
  background-color: transparent;
  right: 0;
  overflow: hidden;
}
.outer-register-button {
  visibility: hidden;
}

.navo:hover {
  color: black;
}
.mobile-nav-text {
  visibility: hidden;
  width: 0;
}
.mobile-nav-2-text {
  visibility: hidden;
  width: 0;
}

.resp {
  /* color: white; */
  margin: 30px;
  /* Can Change the font-size later */
  font-size: 16px;
  font-weight: 600;
}

.resp:hover {
  font-weight: bold;
}

.super {
  background-color: none;
}

/* section 2 */
.sec2-header {
  font-size: 3vw;
  margin-bottom: 4%;
}
.sec3-header {
  font-size: 3vw;
  margin-bottom: 4%;
}
.teams-text {
  font-size: 4vw;
  margin-bottom: 2%;
  margin-left: 10vw;
  margin-top: 10vw;
}

.demo-class {
  overflow: hidden;
}
.sec2-text {
  font-size: 1.3vw;
  font-weight: 400;
  line-height: 1.9;
}

.sec3-text-head {
  margin-bottom: 3vh;
  font-size: 4vw;
}

.nav-items-unique {
  display: flex;
  align-items: center;
  margin-right: 7vw;
}

/* section 3 images */

.sec3-container {
  height: auto;
}

.section-img {
  visibility: visible;
  height: auto;
  width: 100%;
  position: absolute;
  z-index: -1;
}

@media screen and (max-width: 1200px) {
  .colnew {
    position: absolute;
    top: 20vw;
    left: 0%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    /* height: 100vh; */
  }

  .resp {
    /* color: white; */
    margin: 18px;
    /* Can Change the font-size later */
    font-size: 14px;
    font-weight: 600;
  }

  .form-control {
    background-color: white;
    border-radius: 5;
    border: none;
    width: 35vw;
    box-shadow: 2px 2px 4px #000000;
  }
  .in-form-img {
    position: absolute;
    z-index: 1;
    margin-top: 10px;
    margin-left: 10%;
    width: 12%;
    left: -1%;
    top: 2vh;
  }
  .submit-button-form {
    background-color: rgb(45, 185, 202);
    width: 17vw;
    padding: 10px;
    margin-bottom: 30px;
    border: none;
    box-shadow: 2px 2px 4px #000000;
  }
}

@media screen and (max-width: 995px) {
  .super {
    background-color: white;
    position: absolute;
    right: -1px;
    top: 52px;
    width: 181px;
    height: 90vh;
    box-shadow: -5px -5px 80px #404040;
  }
}

@media screen and (max-width: 990px) {
  .navbar-nav {
    margin-top: 13vh;
  }

  .nav-resp {
    position: absolute;
    z-index: 1;
    background-color: transparent;
    right: 0;
    overflow: visible;
  }
  .outer-register-button {
    visibility: visible;
  }

  .mobile-nav-text {
    visibility: visible;
    color: #2db9ca;
    text-align: center;
    margin-top: 15%;
    font-size: 25px;
    width: auto;
  }
  .mobile-nav-2-text {
    visibility: visible;
    text-align: center;
    margin-top: 60px;
    font-size: 15px;
    width: auto;
    color: #000000;
  }

  .resp {
    color: black;
    margin: 0px;
  }
  /* .super {
    background-color: white;
    position: absolute;
    right: -1px;
    top: 52px;
    width: 181px;
    height: 90vh;
    box-shadow: -5px -5px 80px #404040;
  } */

  .nav-item {
    margin-bottom: 10%;
  }
  .nav-items-unique {
    text-align: center;
    display: list-item;
    margin-right: 0;
  }

  .nav-item:hover {
    background-color: rgb(227, 241, 243);
  }
  /* .section3-images section Start*/

  /* .section3-images section End*/
}

/* Events Section css styles */
.event-text-main {
  font-size: 4vw;
  margin-bottom: 4%;
  position: absolute;
  top: 17vw;
  right: 8%;
}

@media screen and (max-width: 770px) {
  .section-img {
    visibility: hidden;
  }
  .mySlides-small-cont {
    visibility: visible;
  }
  .slideshow-container {
    /* max-width: 1000px;   used to keep the image width small upto 100px */
    position: relative;
    margin: auto;
    visibility: hidden;
  }
  .tablet-slideshow-container {
    visibility: visible;
    position: absolute;
    top: 2px;
    left: -1%;
  }
  .sec2-main-div {
    background-image: linear-gradient(
      0deg,
      #ffffff 0%,
      rgb(182, 231, 235, 1) 80%
    );
  }
  .team-div-main {
    background-image: linear-gradient(
      0deg,
      rgb(252, 232, 193, 1) 0%,
      #ffffff 100%
    );
  }
}

@media screen and (max-width: 600px) {
  .colnew {
    position: absolute;
    top: 30vw;
    left: 0%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
  }
  .form-control {
    background-color: white;
    border-radius: 5;
    border: none;
    width: 35vw;
    height: 4vh;
    box-shadow: 2px 2px 4px #000000;
  }
  .form-border {
    margin: 10px;
  }
  .in-form-img {
    position: absolute;
    z-index: 1;
    margin-top: 10px;
    margin-left: 10%;
    width: 20%;
    left: -1%;
    top: 2vh;
  }
  .submit-button-form {
    background-color: rgb(45, 185, 202);
    width: 28vw;
    padding: 10px;
    margin-bottom: 30px;
    border: none;
    box-shadow: 2px 2px 4px #000000;
  }
  .event-text-main {
    font-size: 6vw;
    margin-bottom: 4%;
    position: absolute;
    top: 17vw;
    right: 5%;
  }
  .teams-text {
    font-size: 6vw;
    margin-bottom: 2%;
    margin-left: 10vw;
    margin-top: 10vw;
  }
  .sec3-text-head {
    margin-bottom: 3vh;
    font-size: 7vw;
  }
}
/* footer */

.last-container-head {
  margin-top: 300px;
}
.last-container-head .last-head {
  font-size: 57px;
  padding: 50px;
}

.contact-container {
  /* padding: 50px; */
  display: flex;
  align-items: center;
  justify-content: space-around;
}
/* .last-img{
      width: 30%;
  } */
.socialmedia-icons {
  height: 20px;
  width: 20px;
}

.contact-box-2 .box-2-head {
  vertical-align: top;
}
.box-2-head {
  font-weight: bold;
}
.contact-box-3 {
  margin: 0;
  text-align: left;
}
b {
  font-weight: 700;
}

.box-3-head {
  /* font-weight: bold; */
  color: white;
  font-size: 15px;
}

.box-3-contact {
  /* font-weight: bold; */
  color: white;
  font-size: 15px;
}

footer {
  text-align: center;
  margin-top: 20px;
}

.contact-box-1 {
  text-align: center;
  margin-bottom: 5vw;
}
.footerhead {
  position: sticky;
  background-color: #353333;
  /* clip-path: polygon(
    63% 10%,
    100% 0,
    100% 35%,
    100% 100%,
    83% 100%,
    52% 100%,
    18% 100%,
    0 100%,
    0 9%,
    26% 0
  ); */
}

.footerdiv {
  background-color: black;
  clip-path: polygon(
    54% 13%,
    100% 5%,
    100% 43%,
    100% 100%,
    68% 100%,
    32% 100%,
    0 100%,
    0 7%,
    21% 2%
  );
}
.footerimg {
  height: auto;
  width: 100%;
  position: absolute;
  z-index: -1;
  /* clip-path: polygon(
    63% 10%,
    100% 0,
    100% 35%,
    100% 100%,
    83% 100%,
    52% 100%,
    18% 100%,
    0 100%,
    0 9%,
    26% 0
  ); */
}

.footerline {
  display: flex;
  justify-content: space-around;
  position: relative;
}

.scroll-container {
  position: absolute;
  top: 22vw;
  right: 21%;
}

.next {
  width: 3vw;
}

.footer-inner-div {
  display: block;
}

.team-cir-dub {
  width: 19%;
  position: absolute;
  z-index: -1;
  left: 28%;
}
.team-cir-dub-2 {
  width: 19%;
  position: absolute;
  z-index: -1;
  right: 28.9%;
}

.team-circle {
  width: 16%;
  top: -1.3vw;
  position: absolute;
  z-index: -1;
  left: 29.7%;
}
.team-circle-2 {
  width: 16%;
  top: -1.3vw;
  position: absolute;
  z-index: -1;
  right: 42.1%;
}
.team-circle-3 {
  width: 16%;
  top: -1.3vw;
  position: absolute;
  z-index: -1;
  right: 30%;
}

.team-circle-4 {
  width: 16%;
  top: 16.4vw;
  position: absolute;
  z-index: -1;
  left: 0%;
}
.team-circle-5 {
  width: 16%;
  top: 16.4vw;
  position: absolute;
  z-index: -1;
  left: 12%;
}
.team-circle-6 {
  width: 16%;
  top: 16.4vw;
  position: absolute;
  z-index: -1;
  left: 23.5%;
}
.team-circle-7 {
  width: 16%;
  top: 16.4vw;
  position: absolute;
  z-index: -1;
  left: 36.5%;
}
.team-circle-8 {
  width: 16%;
  top: 16.4vw;
  position: absolute;
  z-index: -1;
  right: 36.5%;
}
.team-circle-9 {
  width: 16%;
  top: 16.4vw;
  position: absolute;
  z-index: -1;
  right: 24.5%;
}
.team-circle-10 {
  width: 16%;
  top: 16.4vw;
  position: absolute;
  z-index: -1;
  right: 12.5%;
}
.team-circle-11 {
  width: 16%;
  top: 16.4vw;
  position: absolute;
  z-index: -1;
  right: 0%;
}
@media screen and (max-width: 1030px) {
  .team-circle-4 {
    top: 17.3vw;
  }
  .team-circle-5 {
    top: 17.3vw;
  }
  .team-circle-6 {
    top: 17.3vw;
  }
  .team-circle-7 {
    top: 17.3vw;
  }
  .team-circle-8 {
    top: 17.3vw;
  }
  .team-circle-9 {
    top: 17.3vw;
  }
  .team-circle-10 {
    top: 17.3vw;
  }
  .team-circle-11 {
    top: 17.3vw;
  }
}

.text-cont {
  width: 31vw;
  font-size: 1vw;
  margin: auto;
}

.team-div-main {
  height: 60vw;
  margin-bottom: -5vw;
  position: relative;
  /* background: linear-gradient(210deg, #f182194a, #49ad3b99 160.71%);
  clip-path: polygon(
    61% 3%,
    100% 9%,
    100% 43%,
    100% 100%,
    60% 100%,
    23% 100%,
    0 100%,
    0 5%,
    22% 12%
  ); */
}
.text-content-new {
  width: 78vw;
  font-size: 1vw;
  margin: auto;
}

.team-img {
  width: 12%;
}
.team-first-div {
  /* visibility: visible; */
  padding: 8%;
  margin-bottom: -4%;
  text-align: left;
  position: relative;
  top: -6vw;
}

.team-second-div {
  visibility: hidden;
  padding: 8%;
  margin-bottom: -4%;
  text-align: left;
  position: relative;
  top: -3vw;
}
#myBtn {
  background-color: transparent;
  color: red;
}

.scrollmain {
  transform: rotate(180deg);
  position: fixed;
  /* top: 39.6vw; */
  bottom: 23vh;
  right: 2%;
  width: 3.5vw;
  z-index: 3;
  cursor: pointer;
}
.last-line {
  font-size: 15px;
}
.sec3-main-div {
  margin-top: 80px;
  padding-left: 8%;
  padding-top: 8%;
  padding-right: 8%;
  text-align: left;
  overflow: hidden;
  max-width: 92%;
}
.in-banner-img {
  position: absolute;
  width: 28vw;
  height: auto;
  right: 14%;
  margin-top: 11vw;
}

@media screen and (max-width: 1200px) {
  .footerdiv {
    background-color: black;
    clip-path: none;
  }
}

@media screen and (max-width: 770px) {
  .last-container-head .last-head {
    font-size: 37px;
    padding: 50px;
  }
  .contact-container {
    /* padding-left: 50px; */
    display: flex;
    align-items: flex-start;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .contact-box-3 {
    margin: 30px;
    text-align: center;
  }
  .sec3-main-div {
    margin-top: 0px;
    padding: 8%;
    text-align: left;
    overflow: hidden;
    max-width: 92%;
  }

  .footerline {
    display: block;
  }

  .sec2-header {
    font-size: 5vw;
    margin-bottom: 4%;
    text-align: center;
  }
  .sec3-header {
    font-size: 5vw;
    margin-bottom: 4%;
    width: fit-content;
  }

  .sec2-text {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 100;
  }
  .footerhead {
    position: sticky;
    background-color: #353333;
    clip-path: polygon(
      62% 0,
      100% 0,
      100% 35%,
      100% 100%,
      83% 100%,
      52% 100%,
      18% 100%,
      0 100%,
      0 0,
      26% 0
    );
  }

  .footer-inner-div {
    display: flex;
  }

  /* looks better with the fooer hidden in this view  */
  .footerimg {
    visibility: hidden;
    height: auto;
    width: 100%;
    position: absolute;
    z-index: -1;
    clip-path: polygon(
      62% 0,
      100% 0,
      100% 35%,
      100% 100%,
      83% 100%,
      52% 100%,
      18% 100%,
      0 100%,
      0 0,
      26% 0
    );
  }
  .team-img {
    width: 20%;
  }

  .team-circle {
    width: 30%;
    position: absolute;
    z-index: -1;
    left: 21%;
  }
  .team-cir-dub {
    width: 26%;
    position: absolute;
    z-index: -1;
    left: 25%;
  }
  .team-cir-dub-2 {
    width: 26%;
    position: absolute;
    z-index: -1;
    right: 25.9%;
  }

  .team-circle-2 {
    width: 30%;
    position: absolute;
    z-index: -1;
    right: 21.9%;
  }

  .team-div-main {
    height: 60vw;
    margin-bottom: -5vw;
    position: relative;
    /* background: linear-gradient(210deg, #f182194a, #49ad3b99 160.71%);
    clip-path: polygon(
      61% 3%,
      100% 9%,
      100% 43%,
      100% 100%,
      60% 100%,
      23% 100%,
      0 100%,
      0 5%,
      22% 12%
    ); */
  }
  .next {
    width: 5vw;
  }
  .text-cont {
    font-size: 2.6vw;
  }
  .team-first-div {
    /* visibility: hidden; */
    padding: 8%;
    margin-bottom: -4%;
    text-align: left;
    position: relative;
    top: 2vw;
  }

  .team-second-div {
    visibility: visible;
    padding: 8%;
    margin-bottom: -4%;
    text-align: left;
    position: relative;
    top: 2vw;
  }

  .scroll-container {
    top: 8.5vw;
    right: 10%;
  }
  .scrollmain {
    visibility: hidden;
  }
  .box-3-head {
    /* font-weight: bold; */
    color: white;
    font-size: 12px;
    text-align: center;
  }
  .box-3-contact {
    font-weight: bold;
    color: white;
    font-size: 10px;
    margin-right: 10px;
  }
  .section-img {
    height: 350px;
  }
  .last-line {
    font-size: 10px;
    margin-bottom: 0px;
    text-shadow: 2px 2px 4px #000000;
  }
}

@media screen and (max-width: 480px) {
  /* .sec3-container {
    height: 306px;
  } */
  .in-banner-img {
    position: absolute;
    width: 28vw;
    height: auto;
    right: 35%;
    margin-top: 11vw;
  }
  .col {
    position: absolute;
    top: 38vw;
    left: 2%;
  }

  .sec2-text {
    font-size: 11px;
    line-height: 1.7;
  }
  .section-img {
    height: 306px;
  }
  .in-img {
    position: absolute;
    z-index: 1;
    margin-top: 5px;
    margin-left: 10%;
    width: 14%;
  }
  .in-text {
    color: rgb(0, 0, 0);
    font-size: 4vw;
    text-align: center;
    margin-left: 0;
  }

  .text-center {
    position: relative;
    height: 300px;
  }
  .back-main-img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 400px) {
  .mobile-nav-2-text {
    visibility: visible;
    text-align: center;
    margin-top: 30px;
    font-size: 15px;
    width: auto;
    color: #000000;
  }
  .in-banner-img {
    position: absolute;
    width: 38vw;
    height: auto;
    right: 31%;
    margin-top: 11vw;
  }
  .col {
    position: absolute;
    top: 50vw;
    left: 0%;
  }
}
